import * as React from 'react';
import memoize from 'memoize-one';
import ClassNames from '@streamloots/classnames';
import type { InputTextProps } from 'ui-library/form-elements/index';
import { latinize } from 'utils/latinize';
import { filterFuzzySearch } from 'utils/fuzzyFilter';
import { StrictOmit } from '@streamloots/streamloots-types';
import type { DropdownOption } from './types';
import { DropdownBase } from './DropdownBase';
import theme from './form.scss';

const classNames = ClassNames(theme);

export interface AutocompleteProps extends StrictOmit<InputTextProps, 'onChange' | 'value' | 'onBlur'> {
  placeholder?: string;
  options: Array<DropdownOption>;
  renderOption?: (option: DropdownOption) => React.ReactNode;
  optionsClassName?: string;
  propertyToSearchFor?: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  value?: string;
}

interface AutocompleteState {
  active?: boolean;
  internalValue?: string;
  selectedOptionFocused: number;
}

interface OptionWithNormalizedLabel extends DropdownOption {
  normalizedLabel: string;
}

class Autocomplete extends React.PureComponent<AutocompleteProps, AutocompleteState> {
  input?: HTMLInputElement;

  getNormalizedOptions = memoize(
    (propertyToSearchFor = 'label', options: Array<DropdownOption>): OptionWithNormalizedLabel[] => {
      return options.map(option => {
        const propertyToNormalize = option[propertyToSearchFor] || '';
        const normalizedLabel = latinize(`${propertyToNormalize} ${option.label}`);

        return {
          ...option,
          normalizedLabel,
        };
      });
    },
  );

  getFilteredOptions = memoize((value = '', options: OptionWithNormalizedLabel[]): OptionWithNormalizedLabel[] => {
    return filterFuzzySearch(value, options);
  });

  getLabelFromValue = memoize((value: string, options: DropdownOption[]) => {
    const selectedValue = options.find(option => option.value === value);
    return selectedValue ? selectedValue.label : value;
  });

  getValueFromLabel = memoize((value: string, options: DropdownOption[]) => {
    const selectedValue = options.find(option => option.label === value);
    return selectedValue ? String(selectedValue.value) : value;
  });

  static defaultProps = {
    className: '',
    options: [],
  };

  state: AutocompleteState = {
    selectedOptionFocused: 0,
  };

  getOptions = () => {
    const { options, propertyToSearchFor } = this.props;
    const { internalValue } = this.state;
    const normalizedOptions = this.getNormalizedOptions(propertyToSearchFor, options);
    return this.getFilteredOptions(internalValue, normalizedOptions);
  };

  getDisplayValue = () => {
    const { value = '', options } = this.props;
    const { internalValue } = this.state;
    return typeof internalValue !== 'undefined' ? internalValue : this.getLabelFromValue(value, options);
  };

  handleChange = event => {
    const { internalValue, selectedOptionFocused } = this.state;
    const { value } = event.target;

    if (value === internalValue) {
      return;
    }

    const newState: AutocompleteState = {
      internalValue: value,
      selectedOptionFocused,
    };

    if (selectedOptionFocused !== 0) {
      newState.selectedOptionFocused = 0;
    }
    this.setState(newState);
  };

  handleOptionClick = (option: DropdownOption) => {
    this.setState({ internalValue: option.label }, () => {
      this.input?.blur();
    });
  };

  handleChangeOptionFocus = (newIndex: number) => {
    this.setState({
      selectedOptionFocused: newIndex,
    });
  };

  handleBlur = () => {
    const { onBlur, onChange, options, value } = this.props;
    const { internalValue } = this.state;

    if (typeof internalValue !== 'undefined' && value !== internalValue) {
      const selectedValue = this.getValueFromLabel(internalValue, options);
      onChange(selectedValue);

      if (onBlur) {
        onBlur(selectedValue);
      }
    }

    this.closeOptions();
  };

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.openOptions();
    if (onFocus) {
      onFocus(event);
    }
  };

  handleKeyDown = event => {
    const { selectedOptionFocused } = this.state;
    const code = event.which;

    if (code === 27) {
      this.input?.blur();
      return false;
    }

    const options = this.getOptions();
    if (!Array.isArray(options) || options.length === 0) {
      return false;
    }

    if (code === 13) {
      const selectedResult = options[selectedOptionFocused];
      this.handleOptionClick(selectedResult);
      return false;
    }

    const lastIndex = options.length - 1;
    if (code === 38) {
      this.handleChangeOptionFocus(selectedOptionFocused > 0 ? selectedOptionFocused - 1 : lastIndex);
      return undefined;
    }

    if (code === 40) {
      this.handleChangeOptionFocus(selectedOptionFocused < lastIndex ? selectedOptionFocused + 1 : 0);
    }
    return undefined;
  };

  openOptions = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({ active: true });
  };

  closeOptions = () => {
    this.setState({ active: false, internalValue: undefined });
  };

  setRef = (node: HTMLInputElement) => {
    this.input = node;
  };

  render() {
    const {
      autoComplete,
      id,
      disabled,
      label,
      labelClassName = '',
      required,
      className = '',
      optionsClassName,
      renderOption,
      value,
      error,
      placeholder,
    } = this.props;
    const { active, selectedOptionFocused } = this.state;
    const options = this.getOptions();

    return (
      <DropdownBase
        options={options}
        renderOption={renderOption}
        labelClassName={labelClassName}
        label={label}
        value={value}
        active={active}
        required={required}
        error={error}
        id={id}
        closeOptions={this.closeOptions}
        onOptionClick={this.handleOptionClick}
        optionsClassName={optionsClassName}
        selectedOptionFocused={selectedOptionFocused}
        onChangeOptionFocus={this.handleChangeOptionFocus}
      >
        <input
          autoComplete={autoComplete}
          id={id}
          ref={this.setRef}
          className={classNames({
            'dropdown__input dropdown__input--autocomplete': true,
            [className]: Boolean(className),
          })}
          type="text"
          required={required}
          placeholder={placeholder}
          value={this.getDisplayValue()}
          disabled={disabled}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
        />
        <svg className={theme.dropdown__arrow} width="10" height="5" viewBox="0 0 10 5" fillRule="evenodd" fill="white">
          <title>Open drop down</title>
          <path d="M10 0L5 5 0 0z" />
        </svg>
      </DropdownBase>
    );
  }
}

export default Autocomplete;
