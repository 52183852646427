import fuzzaldrin from 'fuzzaldrin-plus';
import { latinize } from 'utils/latinize';

// this filter is based on https://github.com/segmentio/evergreen/blob/v6.0.1/src/autocomplete/src/Autocomplete.js#L12

const fuzzyFilter = (itemToString?: (item: Record<string, string>) => string) => {
  if (itemToString) {
    return (items, input) => {
      const wrappedItems = items.map(item => ({
        key: itemToString(item),
        item,
      }));

      return fuzzaldrin.filter(wrappedItems, input, { key: 'key' }).map(({ item }) => item);
    };
  }

  return (items, input) => fuzzaldrin.filter(items, input);
};

export const filterFuzzySearch = (value: string, options: Array<{ normalizedLabel: string }>) => {
  if (value === '') {
    return options;
  }

  const latinizedValue = latinize(value.toLowerCase());

  const filterFn = fuzzyFilter(item => item.normalizedLabel);

  return filterFn(options, latinizedValue);
};
